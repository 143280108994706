<template lang="pug">
  #default-wrapper
    #above-footer
      Sidebar(
        :is-hidden="isSidebarHidden"
        @switch="handleSwitchSidebar"
      )
      Body(
        v-if="isLoggedIn"
        :class="bodyClass"
      )
        template(v-slot:header)
          Header
    Footer
</template>

<script>
  // misc
  import { bus } from "@/config"
  import { isLoggedIn } from "@/helpers/auth"

  export default {
    components: {
      Header: () => import("@/components/layout/Header"),
      Sidebar: () => import("@/components/layout/Sidebar"),
      Body: () => import("@/components/layout/Body"),
      Footer: () => import("@/components/layout/Footer")
    },

    mounted() {
      if (window.innerWidth <= 768) {
        this.isSidebarHidden = true
      }
    },

    data() {
      return {
        isSidebarHidden: false
      }
    },

    computed: {
      isLoggedIn,

      bodyClass() {
        if (this.isSidebarHidden) {
          return "sidebar-hidden"
        } else {
          return "sidebar-opened"
        }
      }
    },

    methods: {
      handleSwitchSidebar() {
        this.isSidebarHidden = !this.isSidebarHidden

        bus.emit("sidebar-switched", this.isSidebarHidden)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  #default-wrapper
    min-height: 100vh
    position: relative

    #above-footer
      display: flex
      width: 100%
      height: calc(100vh - #{$footer-height})

      @media screen and (max-width: 768px)
        height: 100vh

      #body
        overflow-y: overlay
        scrollbar-gutter: auto
        height: calc(100vh - #{$footer-height})
        @media screen and (max-width: 768px)
          height: 100vh

        &.sidebar-hidden
          width: calc(100% - #{$sidebar-collapsed-width})

        &.sidebar-opened
          width: calc(100% - #{$sidebar-width})

  @media (max-width: 768px)
    #default-wrapper
      &::after
        display: none
</style>
